document.addEventListener('DOMContentLoaded', function () {
    // Init circlr.js
    document.querySelectorAll('.r360').forEach(el => {
        window.circlr(el, { scroll: true, loader: 'loader' });
    });

    // Navbar
    const scrollLimit = 50;
    window.onscroll = function () {
        document.querySelector('header').classList.toggle('header--fixed', scrollLimit < window.scrollY);
    };
});